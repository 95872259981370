<template>
    <footer class="Footer">
      <div class="Wrapper">
        <div class="Footer-Menu">
          <BaseLogo class="Footer-Logo" />
          <MainNav class="Footer-Nav" />
          <div class="Footer-Wrap">
            <LocaleSwitcher class="Footer-Locale" />
            <Socials />
          </div>
        </div>
        <div v-if="$i18n.locale === 'ru'" class="Footer-Information">
            <span class="Footer-Information-Text"></span>
          <!-- <span class="Footer-Information-Text">Reg address</span> -->
          <span class="Footer-Information-Text Footer-Information-Text-Address">
              BEKING MARKETING PTE. LTD.<br>Reg address 195 PEARL'S HILL TERRACE, #03-38A, SINGAPORE (168976)
          </span>
        </div>
        <div v-if="$i18n.locale === 'en'" class="Footer-Information">
          <span class="Footer-Information-Text"></span>
          <!-- <span class="Footer-Information-Text">Reg address</span> -->
          <span class="Footer-Information-Text Footer-Information-Text-Address">
              BEKING MARKETING PTE. LTD.<br>Reg address 195 PEARL'S HILL TERRACE, #03-38A, SINGAPORE (168976)
          </span>
        </div>
        <!-- EXP-567 -->
        <!-- <div class="Footer-Policy">
          <a
            :href="`${publicPath}docs/${pathPrivacyPolicy}`"
            target="_blank"
            >{{ $t("footer.privacyPolicy") }}
          </a>
        </div> -->
      </div>
    </footer>
  </template>

  <script>
  import MainNav from "./MainNav";
  import LocaleSwitcher from "./LocaleSwitcher";

  export default {
    name: "TheFooter",
    components: { MainNav, LocaleSwitcher },
    data() {
      return {
        publicPath: process.env.BASE_URL
      };
    },
    computed: {
      pathPrivacyPolicy() {
        return `Personal_Data_Processing_and_Privacy_Policy_Syndicate_${this.$i18n.locale}.pdf`;
      }
    }
  };
  </script>

  <style lang="scss">
  .Footer {
    padding: 20px 0 70px;

    @media (min-width: $screen-l) {
      padding: 50px 0 83px;
    }

    &-Menu {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-l) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-Logo {
      align-self: center;
    }

    &-Nav {
      padding: 28px 0 43px;
      @media (min-width: $screen-l) {
        padding: 0;
      }
    }

    &-Wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-Locale {
      margin-right: 81px;
    }

    &-Information {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 30px;
      gap: 16px;

      &-Text {
        text-align: center;
        font-size: 14px;
      }

      @media (min-width: $screen-l) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;

        &-Text {
          text-align: start;

          &-Address {
            max-width: 410px;
          }
        }
      }
    }

    // &-Policy {
    //   margin-top: 30px;
    //   position: absolute;
    //   right: 0;
    //   padding-right: 15px;
    // }
  }
  </style>
